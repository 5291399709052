/**
 * Created by wojtek on 07.11.15.
 */
//"use strict";

var app = angular.module("shop", ["layoutDirectives", "notificationDirectives", "ngDraggable", "angularFileUpload"]);

app.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';
}]);
/**
 * Created by wojtek on 07.11.15.
 */

var layout = angular.module("layoutDirectives", ['viewServices', 'eventsServices']);


layout.directive('minHeightLikeWindow', ['$window', function ($window) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, element, attr) {
            var object = angular.element(element);
            var window = angular.element($window);

            function render() {
                object.css({'min-height': window.innerHeight() + 'px'});
            }

            window.bind('resize', function () {
                render();
            });

            render();
        }
    };
}]);

layout.directive('cookieAlert', ['$compile', 'viewService', function ($compile, viewService) {
    return {
        scope: true,
        link: function (scope, element, attr) {
            var object = angular.element(element);
            var cookieName = 'slavPageCookieCheck';
            var cookieChk = getCookie(cookieName);
            if (cookieChk === null || cookieChk === "") {
                displayAlert();
            }

            function displayAlert() {
                viewService.load('pages.cookies').success(function (data) {
                    if (!data.error) {
                        var cookiePage = angular.element(data.view);
                        object.append(cookiePage);
                        $compile(cookiePage)(scope);
                    }
                });
            }



            function setCookie(cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                document.cookie = cname + "=" + cvalue + "; " + expires;
            }

            function getCookie(cname) {
                var name = cname + "=";
                var ca = document.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') c = c.substring(1);
                    if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
                }
                return "";
            }

            scope.cookieAgree = function () {
                setCookie(cookieName, 'agree', 365);
                object.remove();
            };
        }
    };
}]);

layout.directive('fieldSetter', ['eventService', function (eventService) {
    return {
        scope: true,
        restrict: 'A',
        priority: 1000,
        link: function (scope, element, attr) {
            var object = angular.element(element);
            var defaultClass = attr.defaultClass ? attr.defaultClass : 'field-setter';
            var activeClass = attr.activeClass ? attr.activeClass : 'field-setter-active';
            scope.class = defaultClass;
            object.css('cursor', 'pointer');
            scope.show = false;
            eventService.on('set-' + attr.field, function (data) {
                if (data.value == attr.value) {
                    scope.class = activeClass;
                    scope.show = true;
                } else {
                    scope.class = defaultClass;
                    scope.show = false;
                }
                scope.$apply();
            });

            object.bind('click', function () {
                eventService.emit('set-' + attr.field, {value: attr.value});
            });
        }
    };
}]);

layout.directive('fieldGetter', ['eventService', '$timeout', function (eventService, $timeout) {
    return {
        restrict: 'A',
        scope: true,
        priority: 1,
        link: function (scope, element, attr) {

            eventService.on('set-' + attr.name, function (data) {
                attr.$set('value', data.value);
            });

            $timeout(function () {
                eventService.emit('set-' + attr.name, {value: attr.value});
            }, 200);

        }
    };
}]);


layout.directive('ckeditor', [function () {
    return {
        scope: false,
        restrict: 'A',
        link: function (scope, element, attr) {
            var object = angular.element(element);
            CKEDITOR.replace(object[0], {
                filebrowserImageBrowseUrl: '/laravel-filemanager?type=Images',
                filebrowserBrowseUrl: '/laravel-filemanager?type=Files',
                extraPlugins: 'video,lineheight',
                removePlugins: 'flash,a11yhelp,about,iframe,save,newpage,print, templates,preview,forms,cut,paste,language',
                extraAllowedContent: 'video[*]{*}',
            });
        }
    };
}]);

layout.directive('formSubmit', [function () {
    return {
        restrict: 'A',
        scope: false,
        link: function (scope, element, attr) {
            var object = angular.element(element);
            var formId = attr.formSubmit;

            object.bind('click', function () {
                $(formId).submit();
            });
        }
    };
}]);

layout.directive('bootstrapInit', [function () {
    return {
        restrict: 'A',
        scope: false,
        link: function () {
            $('[data-toggle="tooltip"]').tooltip();
        }
    };
}]);


layout.directive('productsCategorySortable', ['$http', '$window', function ($http, $window) {
    return {
        restrict: 'A',
        scope: true,
        template: '<ul class="products-gallery">' +
        '<li class="col-md-1" ng-drag="true" ng-drag-data="product" ng-repeat="product in category.products" ng-drop="true" ng-drop-success="afterDrop($index, $data, $event)">' +
        '<div class="text-center">' +
        '<img ng-src="{{product.avatar}}" class="img-responsive" />' +
        '<span style="font-size: 0.5em">{{product.title}}</span>' +
        '</div>' +
        '</li>' +
        '</ul>',
        link: function (scope, element, attr) {
            var slug = attr.productsCategorySortable;
            var token = attr.csrfToken;

            $http.get('/admin/products/ajax-category/' + slug)
                .then(function (data) {
                    scope.category = data.data;
                }).then(function (error) {

                });

            scope.afterDrop = function (index, object, event) {
                var newIndex = scope.category.products.indexOf(object);
                scope.category.products.splice(newIndex, 1);
                scope.category.products.splice(index, 0, object);
                saveOrder();
            };

            function saveOrder() {
                var orderArray = [];
                var newOrder = 1;
                for (var i in scope.category.products) {
                    orderArray.push({
                        category_id: scope.category.products[i].pivot.category_id,
                        product_id: scope.category.products[i].pivot.product_id,
                        order: newOrder,
                    });
                    newOrder++;
                }
                $http.post('/admin/products/ajax-set-order', {order: orderArray, _token: token}).then(function (data) {
                    token = data.data.token;
                    //TODO add notification
                }).then(function () {
                    //TODO add notification
                });
            }
        }
    };
}]);

layout.directive('pageInfinityLoop', ['$http', '$window', '$compile', function ($http, $window, $compile) {
    return {
        restrict: 'A',
        scope: true,
        priority: 100,
        template: '<div class="col-xs-12 page-loader"><div ng-show="loading">loading...</div></div>',
        link: function (scope, element, attr) {
            var object = angular.element(element);
            var window = angular.element($window);
            var lastPosition = {};
            scope.params = scope.$eval(attr.params);
            scope.pageNumber = 0;
            scope.onPage = onPageCount();
            scope.pushParam = attr.pushParam;
            scope.loading = false;
            var elementsScope = scope.$new(false);
            var currentPath = $window.location.href;
            //console.log(currentPath);

            scope.goToProduct = function(){
                saveLastPosition();
            };

            //zanim przejdziemy do strony produktu zapisuje stan załadowanych elementów
            function saveLastPosition(){

                lastPosition = {
                    page: object.html(),
                    scrollTop: window.scrollTop(),
                    pageNumber: scope.pageNumber,
                    url: currentPath

                };
                localStorage.setItem('categoryLastPosition', JSON.stringify(lastPosition));
            }

            //jeżeli istnije zapisany stan okna to ładuje
            function loadLastPosition(){
                var localStorageTemp = localStorage.getItem('categoryLastPosition');
                lastPosition = scope.$eval(localStorageTemp);
                localStorage.removeItem('categoryLastPosition');

                if(typeof lastPosition !== "undefined" && lastPosition.url === currentPath){
                    //console.log(lastPosition);
                    scope.loading = true;
                    var page = angular.element(lastPosition.page);

                    $compile(page)(scope);
                    object.html(page);

                    var body = $('html, body');
                    body.animate({scrollTop: lastPosition.scrollTop}, '500', 'swing');

                    scope.pageNumber = lastPosition.pageNumber;
                    scope.loading = false;
                    return true;
                }
                return false;
            }


            function loadPage() {
                if(loadLastPosition()){
                    return;
                }
                scope.pageNumber++;
                scope.loading = true;
                $http.get('/ajax/get-category/' + scope.params.category + '?page=' + scope.pageNumber + '&itemsOnPage=' + scope.onPage + '&available=' + scope.params.available + '&type=' + scope.params.type)
                    .then(function (data) {
                        var page = angular.element(data.data.view);
                        page.addClass('injected-page');
                        object.find('.page-loader').before(page);
                        $compile(page)(elementsScope);
                        scope.loading = false;
                    }).then(function () {
                        //TODO Error notification
                    });
            }


            function onPageCount() {
                var col = 5;
                var size;
                if (window.innerWidth() < 980) {
                    col = 3;
                }
                size = object.innerWidth() / col;
                var row = Math.ceil((window.innerHeight() - size) / size);
                return col * row;

            }

            window.on('scroll', function () {
                if (!scope.loading) {
                    if (object[0].offsetTop + object[0].offsetHeight <= window.height() + window.scrollTop()) {
                        loadPage();
                    }
                }
            });

            window.on('resize', function () {
                //TODO refresch category after resize
                //object.find('.injected-page').remove();
                //elementsScope.$destroy();
                //scope.onPage = onPageCount();
                //scope.pageNumber = 0;
                //elementsScope = scope.$new(false);
                //loadPage();
            });

            loadPage();
        }
    };
}]);

layout.directive('productPresentation', ['$http', '$window', 'eventService', function ($http, $window, eventService) {
    return {
        restrict: 'A',
        scope: true,
        priority: 1000,
        template: '<div></div>',
        link: function (scope, element, attr) {
            var productId = attr.productPresentation;
            var object = angular.element(element);
            var window = angular.element($window);
            var images = [];
            var product;
            var presentationApi;
            var poducts = [];

            $http.get('/ajax/get-product/' + productId).then(function (data) {
                images = scope.$eval(data.data.images_structure);
                product = data.data;
                getCategoryProductIds(data.data);
                render();
            }).then(function () {

            });

            function getCategoryProductIds(product){
                var hthis = this
                $http.get('/ajax/get-product-ids/' + product.id).then(function (data) {
                    hthis.products = data.data;
                }).then(function () {

                });
            }


            function render() {
                var centralFrame = Math.round((images.thumbnails.length + 1) / 2);
                var objectSize = object.width();
                presentationApi = object.children().spritespin({
                    source: images.thumbnails,
                    zoomSource: images.zoom,
                    width: objectSize,
                    height: objectSize,
                    detectSubsampling: true,
                    sense: -2,
                    animate: false,
                    scrollThreshold: 500,
                    renderer: 'image',
                    loop: false,
                    wrap: product.in_loop,
                    mods: ['360', 'zoom', 'move'],
                    frame: centralFrame - 1,
                    onInit: showLoader,
                    onLoad: hideLoader
                }).spritespin("api");

            }

            function showLoader(e, data) {
                data.loader = $("<div class='loader-stage'>loading ...</div>")
                    .css({
                        bottom: 0,
                        right: 0,
                        position: 'absolute'
                    }).appendTo(data.target);
            }

            function hideLoader(e, data) {
                data.loader.hide();
            }

            window.on('resize', function () {
                render();
            });

            window.bind('mousewheel', function () {
                render();
            });

            eventService.on('product-zoom-toggle', function () {
                presentationApi.toggleZoom();
            });

        }
    };
}]);

layout.directive('productThumbnails', ['$http', function ($http) {
    return {
        restrict: 'A',
        scope: true,
        template: '<ul style="list-style: none"><li ng-repeat="img in images" style="width: 50px; display: inline-block"><img ng-src="{{img}}" class="img-responsive" /></li></ul>',
        link: function (scope, object, attr) {
            var productId = attr.productThumbnails;
            $http.get('/ajax/get-product/' + productId)
                .then(function (data) {
                    images = scope.$eval(data.data.images_structure);
                    scope.images = images.thumbnails;
                }).then(function () {

                });
        }
    };
}]);

layout.directive('pleaseWait', [function () {
    return {
        //TODO show message after click button
    };
}]);

layout.directive('searchAll', ['$http', '$sce', function ($http, $sce) {
    return {
        restrict: 'A',
        template: '<div style="margin-bottom: 20px;"><input type="text" class="form-control" ng-model="searchField" placeholder="search" /></div>' +
        '<div ng-repeat="row in searchData">' +
        '<p><a href="{{row.url}}" style="color: #AAA;"><img ng-src="{{row.img}}" width="50" ng-show="row.img" /><b ng-show="row.title">{{row.title}}</b> {{row.text}}</a></p>' +
        '<hr />' +
        '</div>',
        link: function (scope, element, attr) {
            scope.searchData = [];
            scope.$watch('searchField', function (value) {
                if (value) {
                    $http.get('/ajax/search?search=' + value).then(function (data) {
                        scope.searchData = data.data;
                    }).then(function () {

                    });
                } else {
                    scope.searchData = [];
                }
            });

            scope.getHtml = function (html) {
                return $sce.trustAsHtml(html);
            };
        }
    };
}]);

layout.directive('charDisplay', ['eventService', '$compile', '$window', '$timeout', function (eventService, $compile, $window, $timeout) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, element, attr) {
            var object = angular.element(element);
            var window = angular.element($window);
            var index = object.index();
            var timer;
            var charArray = attr.charDisplay.split('');
            scope.controlClass = 'char-hid';
            scope.char = '';

            if (window.width() > 1000) {

                eventService.on('show-char-' + index, function (data) {
                    scope.$apply(function () {
                        scope.controlClass = 'char-show';
                        scope.char = data.char;
                    });

                });

                eventService.on('hide-char', function () {
                    scope.$apply(function () {
                        scope.controlClass = 'char-hid';
                    });

                });

                object.on('mouseenter', function () {
                    //timer = $timeout(function(){
                    var displayIndex = 0;
                    for (var i in charArray) {
                        if (displayIndex == index) {
                            displayIndex++;
                        }
                        //eventService.emit('show-char-' + displayIndex, {char: charArray[i]});
                        displayIndex++;
                    }
                    //}, 500);
                });


                object.on('mouseleave', function () {
                    //$timeout.cancel(timer);
                    //eventService.emit('hide-char', {});
                });

                appendDisplay();
            }

            function appendDisplay() {
                var display = angular.element('<div class="char-display" data-ng-class="controlClass"><p>{{char}}</p></div>');
                object.append(display);
                $compile(display)(scope);
            }
        }
    };

}]);

layout.directive('fileUpload', ['FileUploader', '$http', function (FileUploader, $http) {
    return {
        restrict: 'A',
        template: '<div><input type="file" name="image" class="form-control" nv-file-select="" uploader="uploader" multiple  />' +
        '<div>' +
        '<ul style="list-style: none; padding: 0;"><li ng-repeat="item in uploader.queue" ng-hide="item.progress==100">' +
        '<div class="" style="font-size: 10px;">{{item.file.name}}</div>' +
        '<div class="progress" style="margin-bottom: 0;">' +
        '<div class="progress-bar" role="progressbar" style="width: {{item.progress}}' + '%' + '"></div>' +
        '</div>' +
        '</li>' +
        '</ul>' +
        '</div>' +
        '<button type="button" class="btn btn-success btn-s" ng-click="uploader.uploadAll()" ng-disabled="!uploader.getNotUploadedItems().length">Upload all</button>' +
        '</div>',
        controller: ["$scope", "$element", "$attrs", function ($scope, $element, $attrs) {
            $scope.uploader = new FileUploader({
                url: $attrs.fileUpload,
                formData: [{
                    _token: $attrs.token
                }]
            });

            $scope.uploader.filters.push({
                name: 'imageFilter',
                fn: function (item /*{File|FileLikeObject}*/, options) {
                    var type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|';
                    return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
                }
            });

            $scope.uploader.onCompleteAll = function () {
                if ($attrs.afterUpdateAction) {
                    $http.get($attrs.afterUpdateAction).then(function () {
                        location.reload();
                    });
                }
            };
        }],
        link: function (scope, element, attr) {

        }
    };
}]);

layout.directive('clickEventEmitter', ['eventService', function (eventService) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            var object = angular.element(element);
            object.css('cursor', 'pointer');

            object.bind('click', function () {
                eventService.emit(attr.clickEventEmitter, {value: attr.value});
            });
        }
    };
}]);

layout.directive('popup', ['eventService', '$http', '$compile', 'viewService', function (eventService, $http, $compile, viewService) {
    return {
        restrict: 'A',
        scope: true,
        link: function (scope, element, attr) {
            var object = angular.element(element);
            scope.visible = 'hide';


            scope.close = function () {
                scope.visible = 'hide';
            };

            scope.loadTemplate = function () {
                viewService.load('angular.popup-layout').then(function (data) {
                    var layout = angular.element(data.data.view);
                    object.html(layout);
                    $compile(layout)(scope);
                }).then(function () {

                });
            };

            eventService.on('show-popup', function (data) {
                $http.get(data.value).then(function (view) {
                    var html = angular.element(view.data.view);
                    object.find('.content').html(html);
                    $compile(html)(scope);
                    scope.visible = 'show';
                }).then(function () {

                });
            });
            scope.loadTemplate();
        }
    };
}]);

layout.directive('confirm', [function () {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            object = angular.element(element);

            object.bind('click', function (e) {
                if (confirm(attr.confirm)) {
                    return true;
                }
                return false;
            });
        }
    };
}]);

layout.directive('contextMenuOff', ['$window', function ($window) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            var object = angular.element($window);

            object.bind('contextmenu', function (e) {
                return false;
            });
        }
    };
}]);

layout.directive('contextMenu', ['viewService', 'eventService', '$compile', '$window', function (viewService, eventService, $compile, $window) {
    return {
        restrict: 'A',
        template: '<div class="context-menu content" ng-class="showClass"></div>',
        scope: true,
        link: function (scope, element, attr) {
            var object = angular.element(element);
            var window = angular.element($window);
            var type = attr.type ? attr.type : 'shop';
            scope.showClass = 'close';

            eventService.on('show-shop-context-menu', function (data) {
                viewService.load('layouts.context-menu/?type=' + type).then(function (data) {
                    var menu = angular.element(data.data.view);
                    object.find('.content').html(menu);
                    $compile(menu)(scope);
                    scope.showClass = 'show';
                }).then(function () {
                    //TODO add notification
                });
            });

            function close() {
                scope.$apply(function () {
                    scope.showClass = 'close';
                });
            }

            eventService.on('hide-shop-context-menu', function () {
                close();
            });

            window.on('scroll', function () {
                close();
            });

            window.on('wheel', function () {
                close();
            });


            window.on('touchmove', function () {
                close();
            });
        }
    };
}]);

layout.directive('disabled', [function () {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            var object = angular.element(element);
            object.addClass('disabled');
            object.on('click', function (e) {
                return false;
            });
        }
    };
}]);



/**
 * Created by wojtek on 22.11.15.
 */
var messages = angular.module('notificationDirectives', ['eventsServices', 'viewServices']);

messages.directive('messageListener', ['eventService', 'viewService',function(eventService){
    return {
        restrict: 'A',
        scope: false,
        link: function(scope, element, attr){
            var message = scope.$eval(attr.messageListener);
            eventService.emit(message.type, message);
        }
    };
}]);

messages.directive('fullPageNotification', ['eventService', 'viewService', '$compile', '$timeout', function(eventService, viewService, $compile, $timeout){
    return {
        restrict: 'A',
        scope: false,
        priority: 1000,
        link: function(scope, element, attr){
            //TODO zmienić pokazywanie i howanie za pomocą css wywalić animacje jquery
            var object = angular.element(element);
            eventService.on('messageFromView', function(data){
                viewService.load(data.view).success(function (data) {
                    if(!data.error) {
                        //object.css('display', 'flex');
                        var view = angular.element(data.view);
                        object.append(view);
                        $compile(view)(scope);
                        fadeOut();
                    }
                });
            });

            function fadeOut(){
                $timeout(function(){
                    object.animate({opacity: 0}, 500, function(){
                        object.remove();
                    });
                }, 1500);

            }
        }
    };
}]);


/**
 * Created by wojtek on 22.11.15.
 */
var notification = angular.module('eventsServices', []);

notification.factory('eventService', function(){
    var events = {
        events: {},
        on: function (eventName, fn) {
            this.events[eventName] = this.events[eventName] || [];
            this.events[eventName].push(fn);
        },
        off: function(eventName, fn) {
            if (this.events[eventName]) {
                for (var i = 0; i < this.events[eventName].length; i++) {
                    if (this.events[eventName][i] === fn) {
                        this.events[eventName].splice(i, 1);
                        break;
                    }
                }
            }
        },
        emit: function (eventName, data) {
            if (this.events[eventName]) {
                this.events[eventName].forEach(function(fn) {
                    fn(data);
                });
            }
        }
    };
    return events;
});
/**
 * Created by wojtek on 28.11.15.
 */
var view = angular.module('viewServices', []);

view.factory('viewService', ['$http',function($http){

    var module = {
        load: function(view){
            view = view.replace('.', '_');
            return $http.get('/ajax/get-view/'+view);
        }
    };
    return module;
}]);

view.factory('agentService', ['$window',function($window){
    var module = {};
    return module;
}]);
